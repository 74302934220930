import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Loading from '../../components/loading'
import View from '../../components/presentation/my-club-ticket'
import { BoxGray } from '../../components/template/box'

import authActions from '../../state/auth/actions'
import homeActions from '../../state/home/actions'

class MyClubTicketPage extends React.Component {
  componentDidMount() {
    this.props.checkAuthorization(null, null, null, {
      onSuccess: () => this.props.onLoad(),
    })
  }

  render() {
    if (!this.props.state.readyPageLogin) {
      return <Loading />
    }
    if (!this.props.state.readyPageMyEvent) {
      return <Loading />
    }
    if (this.props.auth.currentUser !== null) {
      return (
        <Layout auth={this.props.auth.currentUser !== null}>
          <div>
            <SEO keywords={[`invitree`]} />
            <BoxGray>
              <View
                locale={this.props.app.locale[this.props.app.lang]}
                location={this.props.location}
              />
            </BoxGray>
          </div>
        </Layout>
      )
    }
    return (
      <Layout auth={this.props.auth.currentUser !== null}>
        <div>
          <SEO keywords={[`invitree`]} />
          <BoxGray>
            <View
              locale={this.props.app.locale[this.props.app.lang]}
              location={this.props.location}
            />
          </BoxGray>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onLoad: homeActions.onLoadMyEvent,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyClubTicketPage)
