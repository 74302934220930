import React, { useState } from 'react'
import styled from 'styled-components'

const RowQiantity = styled.div`
  display: flex;
  div {
    background: #ffffff;
    border: 1px solid #c5c2c2;
    border-radius: 4px;
    height: 32px;
    width: 68px;
    margin: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Button = styled.button`
  cursor: pointer;
  background: transparent;
`
function InputQuantity({ onChange }) {
  const [count, setCount] = useState(0)
  return (
    <RowQiantity>
      <Button
        onClick={() => {
          if (count > 0) {
            setCount(count - 1)
          }
        }}
      >
        -
      </Button>{' '}
      <div>{count}</div>
      <Button onClick={() => setCount(count + 1)}>+</Button>
    </RowQiantity>
  )
}

export default InputQuantity
