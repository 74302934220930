import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import Button from '../form/buttons'
import RowQuantity from './components/input'
import ConfirmTicket from './components/confirm-ticket'
import MediaQuery from 'react-responsive'

const ColumnHeader = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 24px;
  color: #3f3131;
`

const Line = styled.hr`
  border: 0;
  height: 1px;
  background: #f0f0f1;
  margin: 20px 0;
`

const ColumnContainer = styled.div`
  display: flex;
  margin-bottom: 12px;

  & > div:nth-child(1) {
    flex: 5;
  }
  & > div:nth-child(2),
  & > div:nth-child(3) {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

const RowContainer = styled(ColumnContainer)`
  margin-bottom: 0;

  & > div:nth-child(2),
  & > div:nth-child(3) {
    flex: 1;
    height: fit-content;
  }

  & > div:nth-child(2) {
    height: 32px;
  }
`

const TicketHeader = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 20px;
  color: #3f3131;
  letter-spacing: 0.25px;
`

const TicketCondition = styled.div`
  opacity: 0.87;
  font-size: 16px;
  color: #3f3131;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
`

const RowTicket = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 20px;
  color: #463838;
  letter-spacing: 0.25px;
`

const RowTicketAndQuantityResponsive = styled.div`
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 12px !important;
`

export default class ClubTicket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      style: null,
      paymentType: 1,
      ticketState: '',
    }
  }

  componentDidMount() {
    const w = document.documentElement.clientWidth
    const bgColor = this.props.hideBG ? null : { backgroundColor: '#E8E8E8' }
    if (w <= 450) {
      this.setState({ style: { padding: '20px 10px', ...bgColor } })
    } else {
      this.setState({ style: { padding: '20px 0', ...bgColor } })
    }
  }

  styleFont1 = { fontFamily: `'Noto Serif', serif` }
  render() {
    const { paymentType, ticketState } = this.state
    return (
      <div className="row no-gutters club" style={this.state.style}>
        <div style={{ width: '100%' }}>
          {ticketState === '' && (
            <Fragment>
              <div className="club__header">
                <hr className="club__line" />
                <h3 style={this.styleFont1}>{this.props.locale['TICKET']}</h3>
              </div>
              <div
                className="club__card"
                style={{ minHeight: 244, marginTop: 20 }}
              >
                <div style={{ marginBottom: 32 }}>
                  <ColumnHeader>
                    {this.props.locale['TICKET_PRICE']}
                  </ColumnHeader>
                  <Line />
                  <div>
                    <ColumnContainer>
                      <TicketHeader>บัตรเดี่ยว</TicketHeader>
                      <MediaQuery minWidth={769}>
                        <TicketHeader>
                          {this.props.locale['PRICE']}
                        </TicketHeader>
                        <TicketHeader>
                          {this.props.locale['QUANTITY']}
                        </TicketHeader>
                      </MediaQuery>
                    </ColumnContainer>
                    <RowContainer>
                      <TicketCondition>
                        <div>Terms and Conditions:</div>
                        <div>1. Ticket is E-Ticket (QR-code)</div>
                        <div>
                          2. This Ticket is Non Refundable / Non Returnable
                        </div>
                        <div>
                          3. This Ticket is Valid for Date of Show issued Only
                        </div>
                      </TicketCondition>
                      <MediaQuery minWidth={769}>
                        <RowTicket>700 บาท</RowTicket>
                        <RowQuantity />
                      </MediaQuery>
                      <MediaQuery maxWidth={768}>
                        <RowTicketAndQuantityResponsive>
                          <RowTicket>700 บาท</RowTicket>
                          <RowQuantity />
                        </RowTicketAndQuantityResponsive>
                      </MediaQuery>
                    </RowContainer>
                    <Line />
                  </div>
                  <div>
                    <ColumnContainer>
                      <TicketHeader>บัตรกลุ่ม (8ที่นั่ง)</TicketHeader>
                      <TicketHeader>Price</TicketHeader>
                      <TicketHeader>Quantity</TicketHeader>
                    </ColumnContainer>
                    <RowContainer>
                      <TicketCondition>
                        <div>Terms and Conditions:</div>
                        <div>1. Ticket is E-Ticket (QR-code)</div>
                        <div>
                          2. This Ticket is Non Refundable / Non Returnable
                        </div>
                        <div>
                          3. This Ticket is Valid for Date of Show issued Only
                        </div>
                      </TicketCondition>
                      <MediaQuery minWidth={769}>
                        <RowTicket>5,600 บาท</RowTicket>
                        <RowQuantity />
                      </MediaQuery>
                      <MediaQuery maxWidth={768}>
                        <RowTicketAndQuantityResponsive>
                          <RowTicket>5,600 บาท</RowTicket>
                          <RowQuantity />
                        </RowTicketAndQuantityResponsive>
                      </MediaQuery>
                    </RowContainer>
                  </div>
                </div>
                <div>
                  <ColumnHeader>
                    {this.props.locale['SELECT_PAYTMENT_METHOD']}
                  </ColumnHeader>
                  <Line />
                  <div>
                    <Button.Button8
                      style={{
                        padding: 0,
                        width: 200,
                        height: 48,
                        margin: '0 8px 0 0',
                        border: paymentType === 1 ? '' : '2px solid #E8E8E8',
                        color: paymentType === 1 ? '' : '#756866',
                      }}
                      onClick={() => this.setState({ paymentType: 1 })}
                    >
                      {this.props.locale['PAYMENT_BY_QR_CODE']}
                    </Button.Button8>
                    <Button.Button8
                      style={{
                        padding: 0,
                        width: 200,
                        height: 48,
                        margin: '0 0 0 8px',
                        border: paymentType === 2 ? '' : '2px solid #E8E8E8',
                        color: paymentType === 2 ? '' : '#756866',
                      }}
                      onClick={() => this.setState({ paymentType: 2 })}
                    >
                      {this.props.locale['PATMENT_BY_CREDIT_CARD']}
                    </Button.Button8>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button.Button7
                    style={{
                      padding: 0,
                      width: 200,
                      height: 48,
                      margin: '48px 12px 0 12px',
                    }}
                    onClick={() => {
                      this.setState({ ticketState: 'confirm' })
                      window.scrollTo(0, 0)
                    }}
                  >
                    {this.props.locale['BUY_TICKET']}
                  </Button.Button7>
                </div>
              </div>
            </Fragment>
          )}
          {ticketState === 'confirm' && (
            <ConfirmTicket
              {...this.props}
              onBack={() => this.setState({ ticketState: '' })}
            />
          )}
        </div>
      </div>
    )
  }
}
